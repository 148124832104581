import React from "react";

export const LogaZnacek = (props) => {
  return (
    <div id="logaznacek">
        <div className="container">
            <div className="images">
                {props.data? props.data.map((d, i) => ( <img src={d.img} alt={d.img} className="logaznacek-img" /> )) : "loading"}
            </div>
        </div>
    </div>
  );
};
